import React, { useState, useRef } from 'react';
import logowebp from './bass.webp'
import logojpg from './bass.jpg';
import './App.css';
import mp3 from './song3.mp3';
import ogg from './song3.ogg';

function App() {
  const [animation, setAnimation] = useState("App-logo-spin infinite 20s linear");
  const music = useRef(null);

  function handleClick(e) {
    e.preventDefault();
    if (music.current.duration > 0 && !music.current.paused) {
      music.current.pause();
      setAnimation("App-logo-spin infinite 20s linear");
    } else {
      music.current.play();
      setAnimation("App-logo-spin infinite 0.4s linear");
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <a href="/" onClick={handleClick}>
          <picture>
            <source srcSet={logowebp} />
            <img src={logojpg} className="App-logo" alt="My face" fetchpriority="high" style={{ animation }} />
          </picture>
        </a>
        <p className="name">
          | Kalle Laine |
        </p>
        <div className="external">
          <a href="https://www.linkedin.com/in/pahakalle/" title="My Linkedin profile">
            <img alt="Linkedin" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDAiIGhlaWdodD0iNDAiCnZpZXdCb3g9IjAgMCA0MCA0MCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjOGJiN2YwIiBkPSJNMi41IDIuNUgzNy41VjM3LjVIMi41eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiM0ZTdhYjUiIGQ9Ik0zNywzdjM0SDNWM0gzNyBNMzgsMkgydjM2aDM2VjJMMzgsMnoiPjwvcGF0aD48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOCAxNi4wNzdMMTMuNTM4IDE2LjA3NyAxMy41MzggMjcuMDg1IDEzLjUzOCAzMiA4IDMyek0xMC43NjkgMTQuNDYyTDEwLjc2OSAxNC40NjJDOS4xMDggMTQuNDYyIDggMTMuMjg1IDggMTEuODMxczEuMTA4LTIuNTYyIDIuNzY5LTIuNTYyIDIuNyAxLjEwOCAyLjc2OSAyLjU2MkMxMy41MzggMTMuMjg1IDEyLjUgMTQuNDYyIDEwLjc2OSAxNC40NjJ6TTMyLjAyNyAzMmgtNS40NjhjMCAwIDAtOC4yNyAwLTguODg1cy0uMDY4LTIuNzM0LTIuMzkyLTIuNzM0Yy0yLjA1IDAtMi4zOTIgMS45ODItMi4zOTIgMi43MzRzMCA4Ljg4NSAwIDguODg1aC01LjQ2OFYxNi4yODFoNS40Njh2Mi4xMTljMCAwIDEuMDk0LTIuMTE5IDQuMzA2LTIuMTE5IDMuMjgxIDAgNS45NDYgMi4yNTUgNS45NDYgNi44MzRWMzJ6Ij48L3BhdGg+PC9zdmc+"></img>
          </a>
        </div>
        <audio id="song" src={mp3} type="audio/mp3" ref={music}>
          <source src={ogg} type="audio/ogg" />
        </audio>
      </header>
    </div>
  );
}

export default App;
